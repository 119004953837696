import { NodeBlogDataAttributes } from '@generated/content';

// SPSCR-438
export const NEWS_CATEGORY_PATHS: { category: string; path: string }[] = Object.values(
  NodeBlogDataAttributes.FieldCategoryBlogEnum
).map(category => {
  // [TODO] Hotfix - needs to be renamed in the CMS
  if (category === 'AI_&_Tec') {
    return { category, path: 'ai-tech' };
  }

  const path = category.toLowerCase().replaceAll('_', '-').replaceAll('&', '').replaceAll('--', '-');

  return { category, path };
});
