export const CORE_DE = {
  TOP_NAV: {
    SEARCH: 'Suche',
    LANGUAGES: {
      ENGLISH: 'Englisch - EN',
      GERMAN: 'Deutsch - DE',
      EN: 'EN',
      DE: 'DE',
    },
  },
  NOT_FOUND: {
    HEADLINE: 'The answer to the ultimate question is: 404',
    MESSAGE: 'Use the Power of Possibility and click here',
    BUTTON_TEXT: 'Start',
  },
  LOGIN_FORM: {
    BUTTONS: {
      LOGIN: 'Anmelden',
    },
    INPUTS: {
      USERNAME: { PLACEHOLDER: 'Login' },
      PASSWORD: { PLACEHOLDER: 'Passwort' },
    },
    ERROR_MESSAGES: {
      REQUIRED: 'Dieses Feld ist erforderlich',
      LOGIN_FAILED: 'Das eingegebene Passwort oder Benutzername ist falsch',
    },
  },
  SCROLL_TOP: 'Zum Anfang der Seite springen',
};
