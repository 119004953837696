import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { RootActions } from '@store/root.actions';

import { IconComponent } from '@components/common/icon/icon.component';
import { ReactiveComponent } from '@core/components/base/reactive-component';
import { SocialLinksComponent } from '@components/common/social-links/social-links.component';
import { LanguageSelectorComponent } from '../../language-selector/language-selector.component';

@Component({
  standalone: true,
  selector: 'sps-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [SocialLinksComponent, IconComponent, LanguageSelectorComponent, TranslateModule],
})
export class TopNavComponent extends ReactiveComponent {
  protected override i18nRootKey = 'MODULES.CORE.TOP_NAV';


  public showSearchBox(): void {
    this.store.dispatch(RootActions.showSearchDialog());
  }
}
