import { ChatbotService } from '@services/chatbot/chatbot.service';
import { LanguageService } from '@services/language/language.service';
import { PrivacyService } from '@services/privacy/privacy.service';
import { SeoService } from '@services/seo/seo.service';
import { TransferStateManager } from '@lib/utils/transfer-state-manager.service';

export const initApp = (
  transferStateManager: TransferStateManager,
  privacyService: PrivacyService,
  languageService: LanguageService,
  seoService: SeoService,
  chatbotService: ChatbotService
): (() => Promise<boolean>) => {
  return () =>
    transferStateManager
      .init()
      .then(selectedLanguage => languageService.init(selectedLanguage))
      .then(() => privacyService.init())
      .then(() => seoService.init())
      .then(() => chatbotService.init())
      .then(() => true)
      .catch((targetLang: string) => {
        // Only needed for local dev - handled by express on prod
        if (typeof document !== 'undefined') {
          document.location.href = `/${targetLang}`;
        }
        return false;
      });
};
