export const CONTACT_PAGE_DE = {
  BLOCKS: {
    GLOBAL_LOCATIONS: {
      HEADLINE: 'ALLE STANDORTE ANZEIGEN',
      CONTENT:
        'SPS ist in mehr als 20 Ländern tätig und konzentriert sich auf Kunden aus den Bereichen Banken, Versicherungen, Gesundheit und Recht.',
      LINK: {
        LABEL: 'Zu den Standorten',
      },
    },
    SOCIAL_MEDIA: {
      HEADLINE: 'Bleiben Sie dran!',
      CONTENT: 'Folgen Sie uns auf Social Media',
    },
  },
};
