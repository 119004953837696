import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Injectable()
export class SpsPageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(snapshot: RouterStateSnapshot) {
    const _title = this.buildTitle(snapshot);
    const title = _title ? `${_title} | SPS Global` : 'SPS - SPS Global';
    this.title.setTitle(title);
  }
}
