import { ChangeDetectionStrategy, Component, computed, inject, Signal } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { PageMetasSelectors } from '@store/page-metas';

import { ReactiveComponent } from '@core/components/base/reactive-component';
import { JsonLd } from '@interfaces/index';

@Component({
  standalone: true,
  selector: 'sps-json-ld',
  templateUrl: './json-ld.component.html',
  styleUrls: ['./json-ld.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JsonLdComponent extends ReactiveComponent {
  public $html: Signal<SafeHtml | null>;
  private sanitizer = inject(DomSanitizer);

  constructor() {
    super();
    const $jsonLd = this.store.selectSignal(PageMetasSelectors.selectJsonLd);
    this.$html = computed(() => this.buildScript($jsonLd()));
  }

  private buildScript(jsonLd: JsonLd): SafeHtml {
    if (!jsonLd) {
      return null;
    }

    const html = `<script type="application/ld+json">${JSON.stringify(jsonLd)}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
