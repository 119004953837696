export const CONTACT_PAGE_EN = {
  BLOCKS: {
    GLOBAL_LOCATIONS: {
      HEADLINE: 'VIEW GLOBAL LOCATIONS',
      CONTENT:
        'SPS operates in more than 20 countries and focuses on clients in banking, insurance, health, and legal. ',
      LINK: {
        LABEL: 'View all SPS location',
      },
    },
    SOCIAL_MEDIA: {
      HEADLINE: 'Stay tuned!',
      CONTENT: 'Find us on Social Media',
    },
  },
};
