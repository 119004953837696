import { Routes } from '@angular/router';

export const STATIC_PAGES_ROUTES: Routes = [
  {
    path: 'login',
    title: 'Login',
    loadComponent: () => import('./components/login/login.component').then(m => m.LoginComponent),
  },
  {
    path: 'files/pdfs/:nodeId',
    loadComponent: () => import('./components/pdf-viewer/pdf-viewer.component').then(m => m.PdfViewerComponent),
  },
  {
    path: '404',
    title: '404',
    loadComponent: () => import('./components/not-found/not-found.component').then(m => m.NotFoundComponent),
  },
  {
    path: 'search',
    title: 'Search',
    loadComponent: () => import('./components/search-results/search-results.component').then(m => m.SearchResultsComponent),
  },
];
