export const KNOWLEDGE_CENTER_DE = {
  COMPONENTS: {
    INDEX: {
      SUB_HEADLINE:
        'Tauchen Sie jetzt ein und entdecken Sie bewährte Methoden und Expertenrat, um Ihr Unternehmen zu transformieren',
      INTRODUCTION:
        'Entdecken Sie, wie Sie Effizienzen in Geschäftsprozessen freisetzen können, indem Sie auf die breite Palette von Ressourcen von SPS zugreifen.',
      NO_RESULTS: 'Ihre gefilterte Suche ergab keine Treffer.',
      FILTERS: {
        DOCUMENT_TYPES: {
          ALL: 'Alles',
          WHITEPAPER: 'Whitepapers',
          EVENT: 'Events',
          ARTICLE: 'Artikel',
          BROCHURE: 'Broschüren',
          INFOGRAPHIC: 'Infografiken',
          MARKETRESEARCH: 'Market Research',
          CASESTUDY: 'Case Studies',
          FACTSHEET: 'Fact sheets',
          PODCAST: 'Podcasts',
          VIDEOS: 'Videos',
        },
        // KC PHASE 2
        BLOG_CATEGORIES: {
          ALL: 'Alles',
          AITEC: 'AI & Tech',
          ABOUTSPS: 'Über SPS',
          BUSINESSEFFICIENCY: 'Business efficiency',
          CUSTOMEREXPERIENCE: 'Customer experience',
          EMPLOYEEEXPERIENCE: 'Employee experience',
        //  ESG: 'ESG',
          SMARTWORKPLACES: 'Smart workplaces',
        },

        // KC PHASE 1
        SOLUTION_CATEGORIES: {
          ALL: 'Alles',
          DOCUMENT_MANAGEMENT_SERVICES: 'Document management services',
          BUSINESS_PROCESS_SERVICES: 'Business process services',
          INTELLIGENT_AUTOMATION: 'Intelligent automation',
          DIGITAL_TRANSFORMATION: 'Digital transformation',
        },
        INDUSTRY_TYPES: {
          ALL: 'Alles',
          BANKING: 'Banking',
          INSURANCE: 'Insurance',
          LEGAL: 'Legal',
          HEALTHCARE: 'Healthcare',
        },
      },
      FORM: {
        SEARCH: {
          PLACEHOLDER: 'Knowledge Center durchsuchen',
        },
        SELECT: {
          LABELS: {
            DOCUMENT_TYPE: 'Art des Dokuments',
             TOPIC: 'Thema',
            SOLUTION: 'Solution',
            INDUSTRY: 'Industrie',
          },
        },
      },
    },
  },
};
