export const NEWS_EN = {
  HEADER: 'News',
  SUBHEADER: 'Read our latest press releases and recent articles',
  NO_RESULTS: 'Your filtered search gave no results.',
  FORM: {
    SELECT: {
      LABELS: {
        LOCATION: 'Location',
        CATEGORY: 'News Category',
        TOPIC: 'Filter by topic',
      },
    },
    SEARCH: {
      PLACEHOLDER: 'Search our latest press releases and articles...',
    },
  },
  LANGUAGES: {
    LABEL: 'Selected language',
    EN: 'EN',
    DE: 'DE',
  },
  LOCATIONS: {
    ALL: 'All',
    Eu: 'Europe',
    Us: 'United States',
  },
  CATEGORIES: {
    ALL: 'All',
    'AI_&_TEC': 'AI & Tech',
    ABOUT_SPS: 'About SPS',
    BUSINESS_EFFICIENCY: 'Business Efficiency',
    CUSTOMER_EXPERIENCE: 'Customer Experience',
    EMPLOYEE_EXPERIENCE: 'Employee Experience',
    SMART_WORKPLACES: 'Smart Workplaces',
  },
  CTA: {
    HEADLINE: 'Newsletter',
    MESSAGE: 'Sign up for our free newsletter to receive SPS Global latest information.',
  },
};
