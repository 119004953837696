export const CORE_EN = {
  TOP_NAV: {
    SEARCH: 'Search',
    LANGUAGES: {
      ENGLISH: 'English - EN',
      GERMAN: 'German - DE',
      EN: 'EN',
      DE: 'DE',
    },
  },
  NOT_FOUND: {
    HEADLINE: 'The answer to the ultimate question is: 404',
    MESSAGE: 'Use the Power of Possibility and click here',
    BUTTON_TEXT: 'Start',
  },
  LOGIN_FORM: {
    BUTTONS: {
      LOGIN: 'Login',
    },
    INPUTS: {
      USERNAME: { PLACEHOLDER: 'Login' },
      PASSWORD: { PLACEHOLDER: 'Password' },
    },
    ERROR_MESSAGES: {
      REQUIRED: 'This field is required',
      LOGIN_FAILED: 'The password or username is incorrect',
    },
  },
  SCROLL_TOP: 'Scroll to top of page',
};
