import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { SpsLogger } from '@lib/utils/logger';

export class SpsUrlSerializer extends DefaultUrlSerializer implements UrlSerializer {
  private readonly invalidChars = ['(', ')'];

  public override parse(url: string): UrlTree {
    try {
      this.invalidChars.forEach(invalidChar => {
        if (url.includes(invalidChar)) {
          const hexReplacement = invalidChar.charCodeAt(0).toString(16);
          url = url.replaceAll(`${invalidChar}`, `%${hexReplacement}`);
        }
      });
    } catch (e) {
      SpsLogger.warn(`[ERROR] SpsUrlSerializer error parsing url ${url}.`);
    }

    return super.parse(url);
  }
}
