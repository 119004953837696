import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from '@ngx-translate/core';

import { Observable, of } from 'rxjs';
import { SpsLogger } from '@lib/utils/logger/sps-logger';
import { TLanguage } from '@core/config/lanugages';
import { SPS_TRANSLATIONS } from '@lib/i18n';

export class SpsI18nLoader implements TranslateLoader {
  public getTranslation(lang: TLanguage): Observable<any> {
    return of(SPS_TRANSLATIONS[lang] || SPS_TRANSLATIONS['EN']);
  }
}

export class SpsMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    SpsLogger.warn(`Missing translation: ${params.key}`);

    return null;
  }
}
