import { afterNextRender, inject, Injectable, Injector } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter, map } from 'rxjs';

import { CUSTOM_GPT, IS_SERVER } from '@core/config';

@Injectable({ providedIn: 'root' })
export class ChatbotService {
  private isServer = inject(IS_SERVER, { optional: true });
  private router = inject(Router);
  private injector = inject(Injector);

  public init(): void {
    if (this.isServer) {
      return;
    }

    this.router.events
      .pipe(
        filter(ev => ev instanceof NavigationEnd),
        map(ev => CUSTOM_GPT.enabledPages.includes((ev as NavigationEnd).url))
      )
      .subscribe(botEnabled => {
        if (botEnabled) {
          this.handleBotEnabled();
          return;
        }

        this.removeScript();
        this.removeContainer();
      });
  }

  private handleBotEnabled(): void {
    this.afterNextRender(() => {
      this.injectContainer();
      this.injectScript();

      return;
    });
  }

  // Hook for testing
  private afterNextRender(cb: () => void): void {
    afterNextRender(cb, { injector: this.injector });
  }

  private injectContainer(): void {
    const container = document.createElement('div');
    container.id = CUSTOM_GPT.divId;
    document.querySelector('sps-custom-cms-page .container').appendChild(container);
  }

  private injectScript(): void {
    const script = this.buildScript();
    document.head.appendChild(script);
  }

  private buildScript(): HTMLScriptElement {
    const script = document.createElement('script');

    script.setAttribute('id', CUSTOM_GPT.scriptId);
    script.setAttribute('p_key', CUSTOM_GPT.key);
    script.setAttribute('p_id', CUSTOM_GPT.id);
    script.setAttribute('div_id', CUSTOM_GPT.divId);
    script.setAttribute('width', '100%');
    script.setAttribute('height', CUSTOM_GPT.iframeHeight);
    script.setAttribute('src', CUSTOM_GPT.scriptSrc);

    return script;
  }

  // Script does not provide any means of disabling and references "document.currentScript" which will not be executed
  // upon page reload.
  private removeScript(): void {
    const script = document.getElementById(CUSTOM_GPT.scriptId);
    script?.remove();
  }

  private removeContainer(): void {
    const container = document.getElementById(CUSTOM_GPT.divId);
    container?.remove();
  }
}
