import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, Component, effect, OnInit, signal, WritableSignal } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { BaseComponent } from '@core/components';
import { RootActions } from '@store/root.actions';
import { DialogComponent } from '@components/root/dialog/dialog.component';
import { FooterComponent } from '@components/root/footer/footer.component';
import { HeaderComponent } from '@components/common/header/header.component';
import { JsonLdComponent } from '@components/root/json-ld/json-ld.component';
import { LoadingIndicatorComponent } from '@components/common/loading-indicator/loading-indicator.component';
import { NewsletterSubscriptionCtaComponent } from '@components/common/_ctas/newsletter-subscription-cta/newsletter-subscription-cta.component';
import { ScrollTopComponent } from '@components/common/scroll-top/scroll-top.component';
import { SideWidgetComponent } from '@components/root/side-widget/side-widget.component';
import { SocialWidgetComponent } from '@components/root/social-widget/social-widget.component';
import { ToastComponent } from '@components/common/toast/toast.component';
import { WebinarDialogComponent } from '@components/common/_dialogs/webinar-dialog/webinar-dialog.component';

import { debounce } from 'lodash-es';

@Component({
  standalone: true,
  selector: 'sps-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    DialogComponent,
    FooterComponent,
    HeaderComponent,
    JsonLdComponent,
    LoadingIndicatorComponent,
    RouterOutlet,
    NewsletterSubscriptionCtaComponent,
    ScrollTopComponent,
    SideWidgetComponent,
    SocialWidgetComponent,
    ToastComponent,
    WebinarDialogComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends BaseComponent implements OnInit {
  public $scrollPercentage: WritableSignal<number> = signal(0);

  constructor(private router: Router,private store: Store) {
    super();


    effect(() => this.setCustomProperty('--scroll-percentage', this.$scrollPercentage().toString()));

   
  }


  ngOnInit() {
    if (this.isServer) {
      return;
    }
  //  this.openWebinarDialog();
    this.subscribeToNavigation();
   // this.locationChangeHandler();

    if (CSS.supports('animation-timeline: scroll()')) {
      return;
    }
  
    // TODO: remove when browser support for animation-timeline is high enough (2024-01-25: 68%)
    this.bindScrollHandler();
    
   
  }


  private subscribeToNavigation(): void {
    this.router.events.pipe(filter(ev => ev instanceof NavigationEnd)).subscribe(() => {
      this.$scrollPercentage.set(0);
    });
  }

  private locationChangeHandler():void{
    this.router.events.subscribe(event => {
       console.log(event);
  });

  }

  private bindScrollHandler(): void {
    window.addEventListener(
      'scroll',
      debounce(() => {
        // Opening the nav may resize the body and trigger an update on the scrollPercentage
        if (document.querySelector('sps-main-nav nav')?.classList.contains('active')) {
          return;
        }

        const { scrollHeight, clientHeight } = document.documentElement;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const height = scrollHeight - clientHeight;

        this.$scrollPercentage.set(Math.round((winScroll / height) * 100));
      }, 75)
    );
  }
}
