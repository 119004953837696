export const SHARED_EN = {
  COMPONENTS: {
    PAGINATOR: {
      OF: 'of',
      NEXT_PAGE: 'Next Page',
      OF_PAGES: 'of {{page}}',
      PAGE: 'Page',
      PREV_PAGE: 'Previous Page',
    },
    NEWSLETTER_CTA_COMPONENT: {
      HEADLINE: "Don't miss out!",
      SUBTEXT:
        'Subscribe to our newsletter and empower yourself with the latest industry news, inspiring stories, and actionable insights',
      BUTTON: 'Subscribe to newsletter',
    },
    NEWSLETTER_CTA: {
      HEADLINE: 'Newsletter',
      SUBTEXT: 'Sign up for our free newsletter to receive SPS Global latest information.',
    },
    NEWS_CARD: {
      CTA_BUTTON: 'Read more',
    },
    SOCIAL_LINKS: {
      MESSAGE: 'Share on Social Media',
      TITLE: '{{platform}}',
      LINK_TITLE: 'Visit us on {{platform}}',
      LINK_TITLE_EMAIL: 'Share this article via email',
      SHARE_LINK_TITLE: 'Share this article on {{platform}}',
      EMAIL_BODY: 'Hi!%0D%0A%0D%0AI wanted to share with you something really interesting I found out: {{url}}',
    },
    SOCIAL_WIDGET: {
      HEADLINE: 'Follow us!',
      MESSAGE: 'Stay in the loop with our latest updates on {{platform}}! ',
      BUTTON_TEXT: 'Follow us',
    },
  },
};
