export const NEWS_DE = {
  HEADER: 'News',
  SUBHEADER: 'Lesen Sie unsere neuesten Blog-Artikel',
  FORM: {
    SELECT: {
      LABELS: {
        LOCATION: 'Region',
        CATEGORY: 'Kategorie',
      },
    },
    SEARCH: {
      PLACEHOLDER: 'Suche in unseren neuesten Blog-Artikel',
    },
  },
  LANGUAGES: {
    LABEL: 'Gewählte Sprache',
    EN: 'EN',
    DE: 'DE',
  },
  LOCATIONS: {
    ALL: 'Alles',
    Eu: 'Europa',
    Us: 'USA',
  },
  CATEGORIES: {
    ALL: 'Alles',
    'AI_&_TEC': 'AI & Tech',
    ABOUT_SPS: 'About SPS',
    BUSINESS_EFFICIENCY: 'Business Efficiency',
    CUSTOMER_EXPERIENCE: 'Customer Experience',
    EMPLOYEE_EXPERIENCE: 'Employee Experience',
    SMART_WORKPLACES: 'Smart Workplaces',
  },
  CTA: {
    HEADLINE: 'Newsletter',
    MESSAGE: 'Sign up for our free newsletter to receive SPS Global latest information.',
  },
};
