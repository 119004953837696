import { Routes } from '@angular/router';

import { botAccessGuard } from '@core/guards/bot-access.guard';

export const CMS_PAGES_ROUTES: Routes = [
  {
    path: 'contact-us',
    loadComponent: () => import('./components/contact-page/contact-page.component').then(m => m.ContactPageComponent),
  },
  {
    path: 'kontakt',
    loadComponent: () => import('./components/contact-page/contact-page.component').then(m => m.ContactPageComponent),
  },
  {
    path: '**',
    loadComponent: () => import('./components/custom-cms-page/custom-cms-page.component').then(m => m.CustomCmsPageComponent),
    canActivate: [botAccessGuard],
  },
];
