import { AfterViewInit, Component, inject } from '@angular/core';
import { ViewportScroller } from '@angular/common';

import { BaseComponent } from '@core/components';
import { MainNavComponent } from '@components/root/_navs/main-nav/main-nav.component';
import { TopNavComponent } from '@components/root/_navs/top-nav/top-nav.component';

@Component({
  selector: 'sps-header',
  standalone: true,
  imports: [MainNavComponent, TopNavComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent extends BaseComponent implements AfterViewInit {
  private resizeObserver: ResizeObserver;
  private scroller = inject(ViewportScroller);

  ngAfterViewInit() {
    if (this.isServer) {
      return;
    }

    this.resizeObserver = new ResizeObserver(entries => {
      window.requestAnimationFrame(() => {
        // Component might have been destroyed
        if (!entries[0]) {
          this.resizeObserver?.disconnect();
          return;
        }

        this.setHeaderHeight(entries[0]);
      });
    });

    this.resizeObserver.observe(this.el.nativeElement);
  }

  private setHeaderHeight(el: ResizeObserverEntry): void {
    const headerHeight = el.contentRect.height;
    const containerWidth = Math.floor(document.body.querySelector('.container')?.getBoundingClientRect()?.width) || 1280;
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    this.setCustomProperty('--sps-header-height', `${headerHeight}px`, document.documentElement);
    this.setCustomProperty('--sps-container-width', `${containerWidth}px`, document.documentElement);
    this.setCustomProperty('--sps-scrollbar-width', `${scrollbarWidth}px`, document.documentElement);

    this.scroller.setOffset([0, headerHeight]);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.resizeObserver?.disconnect();
  }
}
