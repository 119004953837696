import { bootstrapApplication } from '@angular/platform-browser';
import { ErrorHandler } from '@angular/core';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

import { SENTRY } from '@core/config';
import { environment } from './environments/environment';
import buildVersion from './public/version.json';

import { init, browserTracingIntegration, createErrorHandler, TraceService } from '@sentry/angular';

const sentryTrackingEnabled = environment.sentryEnabled && SENTRY.TOKEN && SENTRY.DSN;

if (sentryTrackingEnabled) {
  const tracePropagationTargets = [
    'localhost',
    /^https:\/\/spsglobal\.com/,
    /^https:\/\/www.spsglobal\.com/,
    /^https:\/\/swsspstsltnscmsndfrntnd.dev.sites.dropsolid-sites.com/,
    /^https:\/\/swsspstsltnscmsndfrntnd.staging.sites.dropsolid-sites.com/,
    /^https:\/\/swsspstsltnscmsndfrntnd.prodpreview.sites.dropsolid-sites.com/,
  ];

  init({
    dsn: SENTRY.DSN,
    environment: environment.key,
    release: buildVersion.version,
    integrations: [browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.25, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets,
    // Session Replay
    ignoreErrors: SENTRY.IGNORE_ERRORS,
    denyUrls: SENTRY.DENIED_URLS,
  });

  appConfig.providers.push({
    provide: ErrorHandler,
    useValue: createErrorHandler({ showDialog: !environment.production }),
  });

  appConfig.providers.push(TraceService);
}

document.addEventListener('DOMContentLoaded', () => {
  bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
});
