import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { LanguageOption, LANGUAGES } from '@core/config/lanugages';

import { map, Observable } from 'rxjs';
import { select } from '@ngrx/store';
import { PageMetasActions, PageMetasSelectors } from '@store/page-metas';
import { RouterActions } from '@store/router';

import { IconComponent } from '@components/common/icon/icon.component';
import { ReactiveComponent } from '@core/components/base/reactive-component';

@Component({
  standalone: true,
  selector: 'sps-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, AsyncPipe, TranslateModule],
})
export class LanguageSelectorComponent extends ReactiveComponent implements OnInit {
  public visible = false;
  public currentLanguage$: Observable<string>;
  public languages: LanguageOption[] = LANGUAGES;

  ngOnInit(): void {
    this.currentLanguage$ = this.store.pipe(
      select(PageMetasSelectors.selectCurrentLanguage()),
      map(lang => `MODULES.CORE.TOP_NAV.LANGUAGES.${lang}`)
    );
  }

  public setLanguage(language: LanguageOption): void {
    this.store.dispatch(PageMetasActions.setLanguage({ language: language.value }));
    this.store.dispatch(RouterActions.navigate({ url: `/${language.value.toLowerCase()}` }));
  }
}
