<nav id="top-nav">
  <sps-social-links/>
  <ul>
    <li class="search" (click)="showSearchBox()">
      <sps-icon icon="search"></sps-icon>
      {{ i18n('SEARCH') | translate }}
    </li>
    <li class="language">
      <sps-language-selector />
    </li>
  </ul>
</nav>
