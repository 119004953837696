export const FORMS_EN = {
  ANOTHER_ENQUIRY: 'Submit another enquiry',
  SELECT: {
    LABELS: {
      PLEASE_SELECT: 'Please select',
    },
  },
  ERROR_MESSAGES: {
    EMAIL_BLACKLISTED: 'Please, provide a corporate email address to receive a better service',
  },
  NOTIFICATIONS: {
    SUBMITTED: {
      GATED: {
        HEADLINE: 'Thank you',
        SUB_HEADLINE: 'For requesting to download our whitepaper',
        BODY: 'You will receive an email shortly with the whitepaper attached. If you need more information, please submit an enquiry using the link below or contact our head office on the number provided.',
      },
      NEWSLETTER: {
        HEADLINE: 'Thank you',
        SUB_HEADLINE: 'For subscribing to our newsletter!',
        BODY: '',
      },
      STANDARD: {
        HEADLINE: 'Thank you',
        SUB_HEADLINE: 'For submitting your enquiry',
        BODY: 'We respond to all our enquiries within 24 hours. If your matter is urgent, contact our head office.',
      },
    },
    NOTIFICATION_MESSAGE: 'Thank you! Your data has been successfully submitted.',
    NOTIFICATION_BUTTON_TEXT: 'Dismiss',
  },
};
