export const GLOBAL_LOCATIONS_EN = {
  INTRODUCTION: {
    HEADLINE: 'Our locations',
    TEXT: "SPS, as a technology-driven leader, is moving towards a bright future built on a strong and proud past. We accumulate more than 20 years of experience making our clients' lives easier in more than 20 countries, through a client-centered approach that involves excellence and innovation as fundamental values.<br/><br/>With a world-class Net Promoter Score of 81 and a customer retention rate of over 95%, hundreds of clients across various sectors, including Banking, Financial Services, Insurance & Healthcare, trust our solutions and our multidisciplinary team of more than 8,000 specialists to boost business efficiency and productivity.",
  },
  LINK: {
    EMAIL: 'Contact us',
    PARTNER_WEBSITE: 'Visit our partner website',
    OWN_WEBSITE :  "Visit site"
  },
  REGIONS: {
    ALL: 'All',
    APAC: 'APAC',
    EMEA: 'EMEA',
    NA: 'US',
  },
  TYPE: {
    GLOBAL_HEADQUARTER: 'Global Headquarter',
    HEADQUARTERS: 'Headquarters',
  },
  COUNTRIES: {
    CH: 'Switzerland',
    DE: 'Germany',
    US: 'United States',
    UK: 'United Kingdom',
    IT: 'Italy',
    NL: 'Netherlands',
    AT: 'Austria',
    FR: 'France',
    SG: 'Singapore',
    CN: 'China',
    HK: 'Hong Kong',
    VN: 'Vietnam',
    IR: 'Ireland',
    ES: 'Spain',
    IN: 'India',
    PH: 'Philippines',
    TW: 'Taiwan',
    JP: 'Japan',
    AUS: 'Australia',
    NZ: 'New Zealand',
    SC: 'South Korea',
    HU: 'Hungary',
    PL: 'Poland',
  },
};
