import { Routes, UrlSegment } from '@angular/router';
import { NEWS_CATEGORY_PATHS } from '@core/config/news';

export const NEWS_CENTER_ROUTES: Routes = [
  {
    path: '',
    children: [
      {
        matcher: url => {
          try {
            const targetPath = url?.[0]?.path?.toLowerCase() ?? '';

            if (targetPath === '') {
              return { consumed: url };
            }

            const match = NEWS_CATEGORY_PATHS.find(categoryPath => targetPath === categoryPath.path);

            if (match) {
              return {
                consumed: url,
                posParams: { selectedCategory: new UrlSegment(match.category, {}) },
              };
            }

            return null;
          } catch (e) {
            console.log('[NEWS CENTER URL MATCHER] caught error:', e);
            return null;
          }
        },
        loadComponent: () => import('./components/news-index-page/news-index-page.component').then(m => m.NewsIndexPageComponent),
      },
    ],
  },
];
