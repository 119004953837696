export const FORMS_DE = {
  SELECT: {
    LABELS: {
      PLEASE_SELECT: 'Bitte wählen',
    },
  },
  ERROR_MESSAGES: {
    EMAIL_BLACKLISTED: 'Please, provide a corporate email address to receive a better service',
  },
  ANOTHER_ENQUIRY: 'Eine weitere Anfrage erstellen',
  NOTIFICATIONS: {
    NOTIFICATION_MESSAGE: 'Thank you! Your data has been successfully submitted.',
    NOTIFICATION_BUTTON_TEXT: 'Dismiss',
    SUBMITTED: {
      GATED: {
        HEADLINE: 'Vielen Dank',
        SUB_HEADLINE: 'für die Anfrage zum Download unseres Whitepapers',
        BODY: 'Sie werden in Kürze eine E-Mail mit dem Whitepaper im Anhang erhalten. Wenn Sie weitere Informationen benötigen, senden Sie uns bitte eine Anfrage über den unten stehenden Link oder kontaktieren Sie unseren Hauptsitz unter der angegebenen Nummer.',
      },
      STANDARD: {
        HEADLINE: 'Vielen Dank',
        SUB_HEADLINE: 'für Ihre Anfrage',
        BODY: 'Wir werden Ihre Anfragen innerhalb von 24 Stunden bearbeiten. Bei dringenden Anliegen wenden Sie sich bitte an unsere Zentrale.',
      },
    },
  },
};
