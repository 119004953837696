import { inject } from '@angular/core';
import { HttpEvent, HttpRequest, HttpErrorResponse, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';

import { catchError, Observable, throwError } from 'rxjs';

import { IS_SERVER } from '@core/config';
import { REQUEST } from '../../../express.tokens';
import { SpsLogger } from '@lib/utils';

export const sanitizeUrl: (target: string) => string = (target = '') => {
  target = target.toLowerCase();

  try {
    const url = new URL(target);
    const path = url.pathname;
    url.pathname = path.replaceAll(/\/\//g, '/');

    return url.toString();
  } catch (e) {
    SpsLogger.warn(e);
    return target;
  }
};

export const spsInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const isServer = inject(IS_SERVER, { optional: true });
  const cloned = req.clone({ url: sanitizeUrl(req.url) });
  const request = inject(REQUEST, { optional: true });

  return next(cloned).pipe(
    catchError((error: HttpErrorResponse) => {
      if (isServer) {
        if (error.status === 404 && error.url?.includes('translate-path')) {
          SpsLogger.log(`[404] did not find CMS entity for url ${error.url}`);
        } else {
          SpsLogger.log(`HTTP_INTERCEPTOR catch error. URL: ${request?.url}, error: ${error.error?.message}`);
        }
      }

      return throwError(() => error);
    })
  );
};
