import { inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

import { combineLatest, distinctUntilChanged, filter, map, Observable, startWith } from 'rxjs';

import { RootState } from '@store/index';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { PageMetasActions, PageMetasSelectors } from '@store/page-metas';

import { EnvironmentService } from '@services/environment/environment.service';
import { GlobalSpsErrorHandler } from '@lib/utils/global-error-handler';
import { HttpHelper } from '@lib/utils/helpers/http-helper/http-helper';
import { JsonLdBuilder } from '@lib/utils/json-ld-builder/json-ld-builder';
import { LanguageService } from '@services/language/language.service';

import { PageMetas, GeoCoordinates } from '@interfaces/config';
import { Breadcrumb, TSpsSeo } from '@models/interfaces';
import { DEFAULT_CMS_PAGE_METAS, LanguagesEnum, SPS, TLanguage } from '@core/config';
import { SPS_STATIC_HREF_LANG_MAPPING } from '@core/config/seo.config';

@Injectable({ providedIn: 'root' })
export class SeoService {
  private basicTags: string[] = ['description', 'abstract', 'keywords', 'robots'];
  private openGraphTags: string[] = ['og:title', 'og:description', 'og:url', 'og:image'];
  private twitterTags: string[] = ['twitter:card', 'twitter:site', 'twitter:title', 'twitter:description', 'twitter:image'];

  private actions = inject(Actions);
  private document = inject(DOCUMENT);
  private languageService = inject(LanguageService);
  private location = inject(Location);
  private meta = inject(Meta);
  private router = inject(Router);
  private store: Store<RootState> = inject(Store);
  private title = inject(Title);

  constructor() {
    const navEnd$ = this.router.events.pipe(filter(ev => ev instanceof NavigationEnd));

    // Make sure to not display wrong/outdated links
    navEnd$.subscribe(() => {
      this.updateCanonicalLink();
      this.updateHrefLangLinks();
    });

    combineLatest([this.actions.pipe(ofType(PageMetasActions.setCanonicalUrl)), navEnd$])
      .pipe(filter(([canonical]) => !!canonical))
      .subscribe(([{ path }]) => {
        const url = [EnvironmentService.host, this.languageService.selectedLanguage, path].join('/').toLowerCase();
        this.updateCanonicalLink(url);
      });
  }

  get tags(): string[][] {
    return [this.basicTags, this.openGraphTags, this.twitterTags];
  }

  public init(): void {
    this.subscribeToActions();
  }

  // Runs update of meta information as soon as new values of metas and breadcrumbs are emitted
  private buildMetas([seo, breadcrumbs]: [TSpsSeo, Breadcrumb[]]): void {
    const metas = seo ? this.extractCmsMetasConfig(seo) : { ...DEFAULT_CMS_PAGE_METAS };

    metas.jsonLd = JsonLdBuilder.buildJsonLd(metas, breadcrumbs);
    Object.entries(metas).forEach(([key]) => (metas[key] = metas[key] || DEFAULT_CMS_PAGE_METAS[key] || null));

    this.updateMetas(metas);
  }

  private updateMetas(pageMetas: PageMetas): void {
    const { title, description, abstract, keywords, image, robots } = pageMetas;

    this.removeTags();
    this.setTitle(title);

    // Update basic meta tags
    this.buildTags(this.basicTags, [description, abstract, keywords, robots]);

    // Update OpenGraph meta tags
    this.buildTags(this.openGraphTags, [title, description, null, image]);

    // Update Twitter meta tags
    this.buildTags(this.twitterTags, ['summary', '@SPSGlobal', title, description, image]);

    // Set canonical link
    this.updateCanonicalLink(pageMetas.canonical);

    // Set lang attribute
    const lang = this.languageService.selectedLanguage || LanguagesEnum.EN;
    this.document.querySelector('html').setAttribute('lang', lang.toLowerCase());

    // Set hreflang links
    this.updateHrefLangLinks(pageMetas.hrefLang, pageMetas.language);

    this.store.dispatch(PageMetasActions.setMetas({ payload: pageMetas }));
  }

  private setTitle(title: string = DEFAULT_CMS_PAGE_METAS.title): void {
    this.title.setTitle(title);
  }

  // Remove all meta tags
  private removeTags(): void {
    const attributeSelectors = this.tags.flat(2).map(tag => `name='${tag}'`);
    attributeSelectors.forEach(selector => this.meta.removeTag(selector));
  }

  private updateCanonicalLink(url: string = null): void {
    this.document.head.querySelector('link[rel="canonical"]')?.remove();

    if (!url) {
      return;
    }

    const link = this.document.createElement('link');
    link.rel = 'canonical';
    link.href = url;

    this.document.head.appendChild(link);
  }

  private updateHrefLangLinks(hrefLangUrl: string = null, pageLanguage: TLanguage = null) {
    const links = this.document.head.querySelectorAll('link[rel="alternate"]');
    links.forEach(link => link.remove());

    let collection: { de?: string; en?: string; 'x-default': string };

    if (!hrefLangUrl || !pageLanguage) {
      if (!SPS_STATIC_HREF_LANG_MAPPING[this.location.path()]) {
        return;
      }

      const { lang, xDefault, alternate } = SPS_STATIC_HREF_LANG_MAPPING[this.location.path()];
      const [href, alternateHref, defaultHref] = [this.location.path(), alternate.href, xDefault].map(
        href => `${SPS.FE_PROD_URL}${href}`
      );
      collection = { [lang]: href, [alternate.lang]: alternateHref, 'x-default': defaultHref };
    }

    if (!collection) {
      collection = { 'x-default': null };

      const currentUrl = [SPS.FE_PROD_URL, this.location.path()].join('');
      const alternateLanguage = pageLanguage === LanguagesEnum.EN ? LanguagesEnum.DE : LanguagesEnum.EN;

      collection['x-default'] = pageLanguage === 'EN' ? currentUrl : hrefLangUrl;
      collection[pageLanguage] = currentUrl;
      collection[alternateLanguage] = hrefLangUrl;
    }

    Object.entries(collection).forEach(([hrefLang, href]) => {
      const link = this.document.createElement('link');

      link.rel = 'alternate';
      link.hreflang = hrefLang.toLowerCase();
      link.href = href;

      this.document.head.appendChild(link);
    });
  }

  // Helper method to create/update corresponding tag if a value provided
  private buildTags(tags: string[], values: string[]): void {
    const tagsToBuild = tags.reduce((p, tag, i) => (values[i] ? [...p, { name: tag, content: values[i] }] : p), []);

    this.meta.addTags(tagsToBuild, true);
  }

  private extractCmsMetasConfig(seo: TSpsSeo): PageMetas | null {
    if (!seo) {
      return null;
    }

    // Collect desired attributes here
    const attributes: Array<keyof TSpsSeo> = [
      'title',
      'description',
      'abstract_text',
      'keywords',
      'image_src',
      'canonical_url',
      'robots',
      'language',
      'hrefLang',
    ];

    // Mapping attr -> wanted attr name
    const mapping = { abstract_text: 'abstract', image_src: 'image', canonical_url: 'canonical' };

    let geoCoordinates: GeoCoordinates = null;

    if (seo.geo_position) {
      try {
        const [latitude, longitude] = seo.geo_position.split(',').map(item => item?.trim());
        geoCoordinates = latitude && longitude ? { latitude: Number(latitude), longitude: Number(longitude) } : null;
      } catch (e) {
        GlobalSpsErrorHandler.log('Error parsing geo coordinates', e);
      }
    }

    return attributes.reduce(
      (p, attr) => {
        if (!seo[attr]) {
          return p;
        }

        const mappedAttr = mapping[attr] || attr;
        return { ...p, [mappedAttr]: seo[attr] || DEFAULT_CMS_PAGE_METAS[attr] || null };
      },
      { geoCoordinates, robots: DEFAULT_CMS_PAGE_METAS.robots }
    );
  }

  private subscribeToActions(): void {
    this.actions
      .pipe(
        ofType(PageMetasActions.setTitle),
        filter(action => !!action.title),
        map(({ title }) => title)
      )
      .subscribe(this.setTitle.bind(this));

    const seo$: Observable<TSpsSeo> = this.actions.pipe(
      ofType(PageMetasActions.buildMetas),
      map(({ payload }) => payload)
    );

    const breadcrumbs$: Observable<Breadcrumb[]> = this.store.pipe(select(PageMetasSelectors.selectBreadcrumbs), startWith([]));

    combineLatest([seo$, breadcrumbs$])
      .pipe(distinctUntilChanged(HttpHelper.compareObjects))
      .subscribe(coll => this.buildMetas(coll));
  }
}
