export const SHARED_DE = {
  COMPONENTS: {
    PAGINATOR: {
      OF: 'of',
      NEXT_PAGE: 'Nächste Seite',
      OF_PAGES: 'von {{page}}',
      PAGE: 'Seite',
      PREV_PAGE: 'Vorherige Seite',
    },
    NEWSLETTER_CTA: {
      HEADLINE: 'Newsletter',
      SUBTEXT: 'Sign up for our free newsletter to receive SPS Global latest information.',
    },
    NEWS_CARD: {
      CTA_BUTTON: 'Mehr lesen',
    },
    SOCIAL_LINKS: {
      MESSAGE: 'Auf Social Media teilen',
      TITLE: '{{platform}}',
      LINK_TITLE: 'Besuchen Sie uns auf {{platform}}',
      LINK_TITLE_EMAIL: 'Diesen Artikel per E-Mail teilen',
      SHARE_LINK_TITLE: 'Teilen Sie dies auf {{platform}}',
      EMAIL_BODY: 'Hi!%0D%0A%0D%0Ahier ein interessanter Link, den ich mit dir teilen möchte: {{url}}',
    },
    SOCIAL_WIDGET: {
      HEADLINE: 'Folge uns auf LinkedIn!',
      MESSAGE: 'Bleibe am Ball mit den neuesten Updates auf {{platform}}! ',
      BUTTON_TEXT: 'Folgen',
    },
  },
};
