<div class="wrapper" (click)="visible = !visible">
  <div class="current-language">
    <span>{{ currentLanguage$ | async | translate }}</span>
    <sps-icon icon="expand_more" />
  </div>

  @if (visible) {
    <ul>
      @for(language of languages; track language) {
        <li (click)="setLanguage(language)">
          <span>{{ ('MODULES.CORE.TOP_NAV.LANGUAGES.' + language.i18n) | translate }}</span>
        </li>
      }
    </ul>
  }
</div>

@if (visible) {
  <div id="backdrop" (click)="visible = false"></div>
}

