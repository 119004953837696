import { APP_ID, APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  provideRouter,
  TitleStrategy,
  UrlSerializer,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';
import { IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';

import { provideStore } from '@ngrx/store';
import { MinimalRouterStateSerializer, provideRouterStore } from '@ngrx/router-store';

import { provideEffects } from '@ngrx/effects';
import { spsInterceptor } from '@core/interceptors/sps-interceptor';
import { EFFECTS, metaReducers, reducers } from '@store/index';
import { IS_SERVER, LanguagesEnum } from '@core/config';

import { initApp } from '@lib/utils/app-initializer';

import { ChatbotService } from '@services/chatbot/chatbot.service';
import { LanguageService } from '@services/language/language.service';
import { PrivacyService } from '@services/privacy/privacy.service';
import { SeoService } from '@services/seo/seo.service';
import { SpsPageTitleStrategy } from '@lib/utils/page-title-strategy';
import { TransferStateManager } from '@lib/utils/transfer-state-manager.service';

import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SpsI18nLoader, SpsMissingTranslationHandler } from '@core/config/i18n';
import { SpsUrlSerializer } from '@lib/utils/sps-url-serializer';

import { RouterApiModule } from '@generated/router';
import { contentApiConfigFactory, routerApiConfigFactory } from '@core/config/api-configurations';
import { BASE_PATH, ContentApiModule } from '@generated/content';

import { APP_ROUTES } from './app-routes';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(),
    provideStore(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    provideEffects(EFFECTS),
    provideHttpClient(withFetch(), withInterceptors([spsInterceptor])),
    provideRouter(
      APP_ROUTES,
      withRouterConfig({ onSameUrlNavigation: 'reload' }),
      withInMemoryScrolling({ scrollPositionRestoration: 'top', anchorScrolling: 'disabled' }),
      withEnabledBlockingInitialNavigation(),
      withViewTransitions(),
      withComponentInputBinding()
    ),
    provideRouterStore({ serializer: MinimalRouterStateSerializer }),
    importProvidersFrom(
      ContentApiModule.forRoot(contentApiConfigFactory),
      RouterApiModule.forRoot(routerApiConfigFactory),
      TranslateModule.forRoot({
        defaultLanguage: LanguagesEnum.EN,
        loader: {
          provide: TranslateLoader,
          useClass: SpsI18nLoader,
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: SpsMissingTranslationHandler,
        },
      })
    ),
    { provide: IS_SERVER, useValue: false },
    { provide: APP_ID, useValue: 'SPSGLOBAL' },
    { provide: TitleStrategy, useClass: SpsPageTitleStrategy },
    {
      provide: BASE_PATH,
      useFactory: (languageService: LanguageService) =>
        [environment.apiHost, languageService.selectedLanguage, 'jsonapi'].join('/').toLowerCase(),
      deps: [LanguageService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [TransferStateManager, PrivacyService, LanguageService, SeoService, ChatbotService],
      multi: true,
    },
    { provide: IMAGE_CONFIG, useValue: { placeholderResolution: 200, disableImageSizeWarning: environment.production } },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        return config.width ? config.loaderParams[config.width] || config.src : config.src;
      },
    },
    { provide: UrlSerializer, useClass: SpsUrlSerializer },
  ],
};
